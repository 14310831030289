import React from "react";
import { useTable, useSortBy } from "react-table";
import tw, { styled } from "twin.macro";

const StyledTable = styled.table`
  ${tw`w-full min-w-[500px] text-sm sm:text-base`}

  th {
    ${tw`text-left`}
  }
  th:first-child {
    ${tw`text-left`}
  }
  td {
    ${tw`text-left`}
  }
  td:first-child {
    ${tw`text-left`}
  }
`;

const TableHead = styled.th`
  ${tw`text-white px-2 py-1.5 text-base sm:text-lg`}
  width: ${(props) => `${props.width}px`};
  background-color: ${(props) => props.color};
`;

const TableCell = styled.td`
  ${tw`px-2 py-1 border-collapse border border-white`}
  ${({ isEven }) => (isEven ? tw`bg-white` : tw`bg-gray-100`)}
`;

const SortSymbol = styled.span`
  ${tw`text-[8px]`}
`;

// const Pagination = styled.div`
//   ${tw`flex justify-between w-full px-2 py-1 text-[#EC1F27] font-bold`}
// `
// const PageButton = styled.button`
//   ${tw`mx-2 disabled:opacity-30`}
// `
// const SideButton = styled.button`
//   ${tw`disabled:opacity-30`}
// `

const Table = ({ items, headers, color }) => {
  const data = React.useMemo(
    () => items,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const columns = React.useMemo(
    () => headers,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const tableInstance = useTable({ columns, data }, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    // page,
    prepareRow,
    // pageCount,
    // state: { pageIndex }, // default page size is 10
    // gotoPage,
    // previousPage,
    // nextPage,
    // canPreviousPage,
    // canNextPage,
  } = tableInstance;

  return (
    <>
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHead
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({
                      width: column.width,
                    })
                  )}
                  color={color}
                >
                  {column.render("Header")}
                  <SortSymbol>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " ▲"
                        : " ▼"
                      : "  "}
                  </SortSymbol>
                </TableHead>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, idx) => {
            prepareRow(row);
            const isEven = idx % 2 === 0;
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <TableCell {...cell.getCellProps()} isEven={isEven}>
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
      {/* {pageCount > 1 && (
        <Pagination>
          <SideButton
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </SideButton>
          <span>
            {pageIndex - 2 >= 0 && (
              <PageButton onClick={() => gotoPage(pageIndex - 2)}>
                {pageIndex - 1}
              </PageButton>
            )}
            {pageIndex - 1 >= 0 && (
              <PageButton onClick={() => gotoPage(pageIndex - 1)}>
                {pageIndex}
              </PageButton>
            )}
            <PageButton disabled={true}>{pageIndex + 1}</PageButton>
            {pageIndex + 1 < pageCount && (
              <PageButton onClick={() => gotoPage(pageIndex + 1)}>
                {pageIndex + 2}
              </PageButton>
            )}
            {pageIndex + 2 < pageCount && (
              <PageButton onClick={() => gotoPage(pageIndex + 2)}>
                {pageIndex + 3}
              </PageButton>
            )}
          </span>
          <SideButton onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </SideButton>
        </Pagination>
      )} */}
    </>
  );
};

export default Table;
